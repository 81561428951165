import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
  @Input() isVisible = false;
  @Input() tableData: any = [];
  @Input() tableColumns: {
    header: string;
    field: string;
    align?: string;
    type?: string;
  }[] = [];
  @Input() tableTitle: any = location;
  @Output() onClose = new EventEmitter<boolean>();
  @Input() isModalLoading: boolean = true;
  // currentPage: number = 1;
  // pageSize: number = 3;

  handleOk(): void {
    this.isVisible = false;
    this.onClose.emit(this.isVisible);
  }

  handleCancel(): void {
    this.isVisible = false;
    this.onClose.emit(this.isVisible);
  }
  currentPage = 1; // Initialize to the first page
  nzPageSize = 3; // This should match your [nzPageSize] in the template

  // Handler for page changes
  onPageChange(event: number): void {
    this.currentPage = event;
  }

  // You may also need a handler for page size changes if that's an option in your UI
  onPageSizeChange(newSize: number): void {
    this.nzPageSize = newSize;
  }
}
