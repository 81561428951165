import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private dataUrl = '/assets/map-data.json';
  private authToken = btoa('zyyahdevops:8736248e-2638-11ee-91ce-0242ac150003');
  private baseReportingUrl = environment.reporting;

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ Authorization: `Basic ${this.authToken}` });
  }

  private getRequest(
    url: string,
    params: string,
    useAuth = false
  ): Observable<any> {
    let options = {};
    if (useAuth) {
      options = { headers: this.getHeaders() };
    }
    return this.http.get(`${url}${params}`, options);
  }

  listDomain(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.listDomain, params);
  }

  totalUser(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.totalUser, params);
  }

  averageUser(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.averageUserSession, params);
  }

  averageTime(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.averageTimeSession, params);
  }

  activeVsHostedSession(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.totalVsHostedSession, params);
  }

  tSessionByWeek(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.sessionByWeek, params);
  }

  tSessionByLocation(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.sessionByLocation, params);
  }

  getMapData(): Observable<any[]> {
    return this.http.get<any[]>(this.dataUrl);
  }

  tUserAndSessionByLocation(params: string): Observable<any> {
    return this.getRequest(
      this.baseReportingUrl.userAndSessionByLocation,
      params
    );
  }

  turnSession(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.turn, params, true);
  }

  stunSession(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.stun, params, true);
  }

  bandwidth1(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.bandwidth1, params, true);
  }

  bandwidth2(params: string): Observable<any> {
    return this.getRequest(this.baseReportingUrl.bandwidth2, params, true);
  }

  tBandwidth(): Observable<any> {
    return this.getRequest(this.baseReportingUrl.totalBandwidth, '', true);
  }
}
