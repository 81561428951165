import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // isLoggedIn = false;
  jwtHelper = new JwtHelperService();
  constructor() {}
  getToken = () => {
    return localStorage.getItem('token');
  };

  isAuthenticated() {
    return this.isTokenValid();
  }

  isTokenValid(): boolean {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    const decodedToken = this.jwtHelper.decodeToken(token);
    localStorage.setItem('role', decodedToken.payload.userID.role);
    if (!decodedToken || !decodedToken.exp) {
      return true; // Token is not valid or has no expiration
    }
    const expirationTimestamp = decodedToken.exp;
    const expirationDate = new Date(expirationTimestamp * 1000);
    return expirationDate > new Date();
  }

  hasRole(allowedRoles: string[], userRole: string): boolean {
    return allowedRoles.includes(userRole);
  }

  decodeJWT(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
