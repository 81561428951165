import { Component, OnInit } from '@angular/core';
import { AllServicesService } from 'src/app/Services/allservices/all-services.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  passwordVisible = false;
  constructor(
    private loginService: AllServicesService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/dashboard/conference']);
    }
  }
  userName: string = '';
  password: string = '';
  loginMessage: string = '';

  login(): void {
    this.loginService.login(this.userName, this.password).subscribe({
      next: (res: any) => {
        const payLoad = this.authService.decodeJWT(res.token);
        localStorage.setItem('token', res.token);
        localStorage.setItem('createdAt', payLoad?.payload?.userID?.createdAt);
        this.router.navigate(['/dashboard/conference']);
      },
      error: (error: any) => {
        console.log(error);
        if (error.error?.msg) {
          this.toastr.error(error?.error?.msg);
        } else {
          this.toastr.error('Malfunction');
        }
      },
    });
  }
}
