import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent {
  @Input() dropDownOption: any;
  @Output() selectedDomainChange = new EventEmitter<string | null>();
  selectedDomain: string = 'All Domains';

  selectDomain(domain: string) {
    this.selectedDomain = domain;
    this.selectedDomainChange.emit(this.selectedDomain);
  }
}
