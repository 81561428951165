import { Component, EventEmitter, Output } from '@angular/core';
import { endOfMonth } from 'date-fns';
@Component({
  selector: 'app-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.css'],
})
export class RangepickerComponent {
  @Output() dateRangeChange = new EventEmitter<Date[]>(); // Event emitter for date range

  selectedDateRange: Date[] = [];
  ranges: { [key: string]: [Date, Date] } = {};

  ngOnInit(): void {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setDate(startOfWeek.getDate() - 7);
    const endOfLastWeek = new Date(endOfWeek);
    endOfLastWeek.setDate(endOfWeek.getDate() - 7);

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const endOfYear = new Date(today.getFullYear(), 11, 31);
    const fromBeginning = new Date(
      localStorage.getItem('createdAt') || new Date()
    );

    this.selectedDateRange = [startOfMonth, endOfMonth];

    this.ranges = {
      'This Week': [startOfWeek, endOfWeek],
      'Last Week': [startOfLastWeek, endOfLastWeek],
      'This Month': [startOfMonth, endOfMonth],
      'Last Month': [startOfLastMonth, endOfLastMonth],
      'This Year': [startOfYear, endOfYear],
      'From Beginning': [fromBeginning, today],
    };
    this.dateRangeChange.emit(this.selectedDateRange);
  }

  onChange(result: Date[]): void {
    this.selectedDateRange = result;
    this.dateRangeChange.emit(result);
  }
}
