import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-sider-drawer',
  templateUrl: './sider-drawer.component.html',
  styleUrls: ['./sider-drawer.component.css'],
})
export class SiderDrawerComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  placement: NzDrawerPlacement = 'left';
  role = localStorage.getItem('role');

  close(): void {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }
}
