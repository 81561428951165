// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../Services/auth/auth.service';

@Injectable({
  providedIn: 'root', // Specify that this service should be provided in the root injector
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {} // Inject the AuthService

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requiredRoles = next.data['roles'] as string[];

    if (this.authService.isAuthenticated()) {
      const userRole = localStorage.getItem('role') || ''; // Fallback to empty string if null
      if (this.authService.hasRole(requiredRoles, userRole)) {
        return true;
      }
    }

    this.router.navigate(['login']);
    return false;
  }
}
