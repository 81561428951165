import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebinarService {
  private baseUrl = environment.webinar;
  private dataUrl = '/assets/map-data.json';

  constructor(private http: HttpClient) {}

  private getRequest(
    url: string,
    params: string,
    useAuth = false
  ): Observable<any> {
    let options = {};
    return this.http.get(`${url}${params}`, options);
  }
  listDomain(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.listDomain, params);
  }
  totalUser(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.totalUser, params);
  }

  averageUser(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.averageUserSession, params);
  }

  averageTime(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.averageTimeSession, params);
  }

  activeVsHostedSession(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.totalVsHostedSession, params);
  }

  tSessionByWeek(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.sessionByWeek, params);
  }

  tSessionByLocation(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.sessionByLocation, params);
  }

  getMapData(): Observable<any[]> {
    return this.http.get<any[]>(this.dataUrl);
  }

  tUserAndSessionByLocation(params: string): Observable<any> {
    return this.getRequest(this.baseUrl.userAndSessionByLocation, params);
  }
}
