<div class="table-div">
  <nz-table #basicTable [nzLoading]="isLoading" [nzData]="data" [nzShowPagination]="showPagination"
    [nzPageSize]="pageSize" (nzPageIndexChange)="onPageChange($event)" [nzPageIndex]="currentPage">
    <thead>
      <tr>
        <th>Sr.no</th>
        <th *ngFor="let col of columns" [style.text-align]="col.align || 'left'">{{ col.header }}</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rowData of basicTable.data let i = index">
        <td style="text-align: center;">{{ (currentPage - 1) * nzPageSize + i + 1 }}</td>

        <td *ngFor="let col of columns" [style.text-align]="col.align || 'left'">
          <ng-container *ngIf="col.type === 'action'; then actionColumn else nonActionColumn"></ng-container>

          <ng-template #actionColumn>
            <div class="table-action">
              <ng-container *ngIf="col.field">
                <span>{{ rowData[col.field] }}</span>
              </ng-container>
              <span class="action-btn" *ngIf="col.action" (click)="col.action(rowData)" nz-icon nzType="eye"
                nzTheme="outline"></span>
            </div>
          </ng-template>

          <ng-template #nonActionColumn>
            <ng-container *ngIf="col.type === 'date' && col.field">
              <span>{{ rowData[col.field] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
            </ng-container>
            <ng-container *ngIf="col.type !== 'date' && col.field">
              <span>{{ rowData[col.field] }}</span>
            </ng-container>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>