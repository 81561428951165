import { Component } from '@angular/core';
import * as worldMap from '@highcharts/map-collection/custom/world.geo.json';
import Highcharts from 'highcharts';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DashboardService } from '../../Services/dashboard.service';
import { WebinarService } from 'src/app/Services/webinarServices/webinar.service';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['../../dashboard/dashboard.component.css'],
})
export class WebinarComponent {
  tableData: any = [];
  isModalLoading = false;
  domainList = [];
  modalTableData: any = [];
  tableTitle: any;
  isVisible: boolean = false;
  show = false;
  totalUsers: any;
  averageTime: any;
  averageUser: any;
  totalUsersArray = [];
  series = [];
  weekData: any;
  domainNames: any;
  totalSession = [];
  dailyData: any = [];
  hostedSession = [];
  abandonedSession = [];
  selectedDomain: string | null = null;
  params = '';
  Highcharts: typeof Highcharts = Highcharts;
  endDate: string = '';
  startDate: string = '';
  mapData: any = [];
  transformedData: any[] = [];
  timeUnit: string = 'sec';
  isLoading = false; // Set to true initially

  constructor(private Service: WebinarService) {}

  getAllData(params: any) {
    this.getActiveHosted(params);
    this.getWeekSessions(params);
    this.getTableData(params);
    this.getMapData(params);
    this.getCardData(params);
  }

  cardData = [
    {
      key: '1',
      title: 'Avg Users /Session',
      number: '0',
      animatedNumber: 0,
    },
    {
      key: '2',
      title: 'Avg Time /Session',
      number: '0',
      animatedNumber: 0,
    },
    {
      key: '3',
      title: 'Total Users',
      number: '0',
      animatedNumber: 0,
    },
  ];
  tableColumn = [
    { header: 'Cities', field: '_id' },
    { header: 'Total Sessions', field: 'totalSessions', align: 'center' },
    {
      header: 'Number of Participants',
      field: 'totalParticipants',
      align: 'center',
    },
  ];
  usersTableColumn = [
    { header: 'Name', field: 'name' },
    { header: 'Email', field: 'email', align: 'center' },
    {
      header: 'Registeration Time',
      field: 'createdAt',
      align: 'center',
    },
  ];
  modalTableColumns = [
    { header: 'Cities', field: '_id' },
    { header: 'Total Sessions', field: 'totalSessions', align: 'center' },
    {
      header: 'Number of Participants',
      field: 'totalParticipants',
      align: 'center',
    },
  ];
  ngOnInit() {
    this.cardData.forEach((card) => {
      card.animatedNumber = 0;
    });
    this.isLoading = true;
    this.Service.listDomain(this.params).subscribe({
      next: (res: any) => {
        this.domainList = res.data;
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
    });
  }
  onDateRangeChange(result: Date[]): void {
    this.startDate = result[0]?.toISOString();
    this.endDate = result[1]?.toISOString();
    const params = this.generateParams();
    this.getAllData(params);
  }
  onDomainSelected(domain: string | null) {
    if (domain === 'All Domains') {
      this.selectedDomain = null;
    } else {
      this.selectedDomain = domain;
    }
    const params = this.generateParams();
    this.getAllData(params);
  }

  formatDate(date: any) {
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1)?.toString().padStart(2, '0');
    const day = date?.getDate()?.toString().padStart(2, '0');
    return `${year}:${month}:${day}`;
  }
  generateParams() {
    let params = '';
    if (this.startDate && this.endDate) {
      params =
        '?startDateRange=' + this.startDate + '&endDateRange=' + this.endDate;
    }
    if (this.selectedDomain != null) {
      params += '&domain=' + this.selectedDomain;
    }
    return params;
  }
  async animateNumbers() {
    await this.cardData.forEach((card) => {
      card.animatedNumber = 0;
    });
    const animationDuration = 1000;
    const animationSteps = 60;
    this.cardData.forEach((card) => {
      const targetNumber = parseInt(card.number, 10);
      const stepValue = Math.ceil(
        (targetNumber - card.animatedNumber) / animationSteps
      );
      let currentNumber = 0;

      const interval = setInterval(() => {
        if (currentNumber >= targetNumber) {
          clearInterval(interval);
        } else {
          currentNumber += stepValue;
          card.animatedNumber = currentNumber;
        }
      }, animationDuration / animationSteps);
    });
  }
  getCardData(params: any) {
    this.Service.averageTime(params).subscribe({
      next: (res: any) => {
        if (res.data.length > 0) {
          const sum = res.data.reduce((sum: number, dataItem: any) => {
            return sum + dataItem.averageTimePerSession;
          }, 0);

          this.averageTime = sum / res.data.length; // Calculate the average

          if (this.averageTime >= 3600) {
            this.averageTime = this.averageTime / 3600; // Convert to hours
            this.timeUnit = 'hours';
          } else if (this.averageTime >= 60) {
            this.averageTime = this.averageTime / 60; // Convert to minutes
            this.timeUnit = 'min';
          } else {
            this.timeUnit = 'sec';
          }
        } else {
          this.averageTime = 0; // Handle the case when the array is empty
          this.timeUnit = 'sec';
        }

        // Set the averageTime here

        const cardToUpdate = this.cardData.find((card) => card.key === '2');
        if (cardToUpdate) {
          cardToUpdate.number = this.averageTime.toString();
        }
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
    });

    this.Service.averageUser(params).subscribe({
      next: (res: any) => {
        if (res.data.length > 0) {
          const sum = res.data.reduce((sum: number, dataItem: any) => {
            return sum + dataItem.averageUsersPerSession;
          }, 0);
          this.averageUser = sum / res.data.length;
        } else {
          this.averageUser = 0;
        }
        const cardToUpdate = this.cardData.find((card) => card.key === '1');
        if (cardToUpdate) {
          cardToUpdate.number = this.averageUser.toString();
        }
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
    });
  }
  getMapData(params: any) {
    this.Service.getMapData()
      .pipe(
        switchMap((mapDataResponse: any) => {
          this.mapData = mapDataResponse.map((item: any) => [
            item.code,
            item.value,
          ]);
          return this.Service.tSessionByLocation(params);
        })
      )
      .subscribe({
        next: (tSessionResponse: any) => {
          // Aggregate session counts by country
          let countrySessionCounts: { [key: string]: number } = {};
          tSessionResponse.data.forEach((element: any) => {
            // Skip if _id is empty
            if (Object.keys(element._id).length === 0) {
              return;
            }

            const countryCode = element._id.country.toLowerCase();
            const count = element.count;
            //Check if countrySessionCount[countryCode] is not initilized
            if (!countrySessionCounts[countryCode]) {
              //If it's not, it's initialized to 0
              countrySessionCounts[countryCode] = 0;
            }
            countrySessionCounts[countryCode] += count;
          });

          // Update mapData with aggregated session counts
          this.mapData.forEach((mapItem: any) => {
            const countryCode = mapItem[0].toLowerCase();
            // checks if there is an aggregated session count for this countryCode
            if (countrySessionCounts[countryCode]) {
              //updates the second element of mapItem with this aggregated session count.
              mapItem[1] = countrySessionCounts[countryCode];
            }
          });

          this.mapSessionsValue();
        },
        error: (err: any) => {
          console.log('error ===> ', err);
        },
        complete: () => {},
      });
  }

  getTableData(params: any) {
    this.Service.tUserAndSessionByLocation(params).subscribe({
      next: (res: any) => {
        this.tableData = res.data.filter((item: any) => item._id !== null);
        this.isLoading = false;
      },
      error: (err: any) => {
        console.log('error==>', err);
      },
      complete: () => {},
    });
  }

  getActiveHosted(params: any) {
    this.Service.activeVsHostedSession(params).subscribe({
      next: (res: any) => {
        this.domainNames = res.data.totalSessions.map(
          (session: any) => session._id
        );
        this.abandonedSession = res.data.abandonedSessions.map(
          (session: any) => session.totalSessions
        );
        this.hostedSession = res.data.activeHostedSessions.map(
          (session: any) => session.totalSessions
        );
        this.totalSession = res.data.totalSessions.map(
          (session: any) => session.totalSessions
        );
        this.getTotalUser(params);
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
      complete: () => {
        this.totalSessionsByDomainValue();
      },
    });
  }

  getTotalUser(params: any) {
    this.Service.totalUser(params).subscribe({
      next: (res: any) => {
        this.totalUsers = res.data.count;
        this.totalUsersArray = res.data.records.map(
          (records: any) => records.totalUsers
        );
        const cardToUpdate = this.cardData.find((card) => card.key === '3');
        if (cardToUpdate) {
          cardToUpdate.number = this.totalUsers.toString();
        }
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
      complete: () => {
        if (this.selectedDomain == null) {
          this.totalUserByDomainValue(this.domainNames, this.totalUsersArray);
        } else {
          this.totalUserByDomainValue(
            [this.selectedDomain],
            this.totalUsersArray
          );
        }
        this.animateNumbers();
      },
    });
  }
  getWeekSessions(params: any) {
    this.Service.tSessionByWeek(params).subscribe({
      next: (res: any) => {
        const transformData = (apiData: any[]) => {
          const result: any[] = [];

          // Iterate through the API data
          apiData.forEach((item: any) => {
            item.domainCounts.forEach((domainCount: any) => {
              const domainName = domainCount.domain;
              const date = new Date(item.date).getDay();
              const existingData = result.find(
                (data) => data.name === domainName
              );
              if (!existingData) {
                const newData = {
                  name: domainName,
                  data: Array(7).fill(0), // Initialize an array with zeros for each day of the week
                };
                result.push(newData);
              }

              // Update the data array with the count for the corresponding day of the week
              result.find((data) => data.name === domainName).data[date] +=
                domainCount.count;
            });
          });

          return result;
        };

        this.transformedData = transformData(res.data);
      },
      error: (error: any) => {
        console.log('error===>', error.error.msg);
      },
      complete: () => {
        this.totalSessionsByWeekValue();
      },
    });
  }

  totalSessionsByWeekValue() {
    this.totalSessionsByWeekUpdate = true;
    if (this.totalSessionsByWeek.xAxis) {
      this.totalSessionsByWeek.xAxis = {
        categories: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
      };
    }
    if (this.transformedData.length === 1) {
      this.totalSessionsByWeek.series = [this.transformedData[0]];
    } else {
      this.totalSessionsByWeek.series = [...this.transformedData];
    }
    setTimeout(() => {
      this.totalSessionsByWeekUpdate = false;
    }, 1000);
  }

  totalSessionsByDomainValue() {
    this.totalSessionsByDomainUpdate = true;
    if (this.totalSessionsByDomain.xAxis) {
      this.totalSessionsByDomain.xAxis = {
        categories: [...this.domainNames],
      };
    }
    this.totalSessionsByDomain.series = [
      {
        type: 'column',
        name: 'Total Sessions',
        data: [...this.totalSession],
      },
      {
        type: 'column',
        name: 'Hosted Sessions',
        data: [...this.hostedSession],
      },
      {
        type: 'column',
        name: 'Abandoned Sessions',
        data: [...this.abandonedSession],
      },
    ];
    setTimeout(() => {
      this.totalSessionsByDomainUpdate = false;
    }, 1000);
  }

  totalUserByDomainValue(domains: any, totalUsersArray: any) {
    this.totalUsersByDomainUpdate = true;
    if (this.totalUsersByDomain.xAxis) {
      this.totalUsersByDomain.xAxis = {
        categories: domains,
      };
    }
    this.totalUsersByDomain.series = [
      {
        type: 'column',
        name: 'Total Users',
        data: totalUsersArray,
      },
    ];

    setTimeout(() => {
      this.totalUsersByDomainUpdate = false;
    }, 1000);
  }

  totalUsersByDomainUpdate = false;
  totalSessionsByDomainUpdate = false;
  totalSessionsByWeekUpdate = false;
  mapSessionsUpdate = false;

  generateHighchartsOptions(
    type: string,
    titleText: string,
    yAxisText: string,
    series: any,
    colors: string[]
  ): Highcharts.Options {
    return {
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'separator',
              'downloadPNG',
              'downloadSVG',
              'downloadPDF',
              'separator',
              'downloadXLS',
            ],
          },
        },
        enabled: true,
      },
      chart: {
        type: type,
      },
      title: {
        text: titleText,
        align: 'left',
      },
      xAxis: {
        categories: [],
        crosshair: true,
        accessibility: {
          description: 'Domains',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisText,
        },
      },
      tooltip: {
        valueSuffix: '',
      },
      plotOptions: {
        [type]: {
          pointPadding: 0.2,
          borderWidth: 0,
          // color: colors[0],
        },
      },
      colors,
      series: series,
    };
  }
  totalSessionsByDomainSeries = [
    {
      type: 'bar',
      name: 'Total Sessions',
      data: [],
    },
    {
      type: 'bar',
      name: 'Hosted Sessions',
      data: [],
    },
    {
      type: 'bar',
      name: 'Abandoned Sessions',
      data: [],
    },
  ];
  totalUsersByDomainSeries = [
    {
      type: 'column',
      name: 'Total Users',
      data: [],
    },
  ];
  totalUsersByDomain: Highcharts.Options = this.generateHighchartsOptions(
    'column',
    'Total users per white label/ Subdomain',
    'Number of Users',
    this.totalUsersByDomainSeries,
    ['#d33138']
  );
  totalSessionsByDomain: Highcharts.Options = this.generateHighchartsOptions(
    'bar',
    'Total Session vs Active Hosted Session',
    'Number of Session',
    this.totalSessionsByDomainSeries,
    ['#d33138', '#000a4b', '#d3d3d3']
  );
  totalSessionsByWeek: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'separator',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadXLS',
          ],
        },
      },
      enabled: true,
    },
    chart: {
      type: 'column',
    },
    title: {
      text: 'Total Session per day',
      align: 'left',
    },
    xAxis: {
      categories: [],
      crosshair: true,
      accessibility: {
        description: 'Domains',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Session',
      },
    },
    plotOptions: {
      bar: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    colors: ['#d33138', '#000a4b', '#d3d3d3'],
    series: [],
  };
  mapChart: Highcharts.Options = {
    chart: {
      map: worldMap,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'separator',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadXLS',
          ],
        },
      },
      enabled: true,
    },
    title: {
      text: 'Number of sessions per location',
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: 'spacingBox',
      },
    },
    legend: {
      enabled: false,
    },
    colorAxis: {
      min: 0,
      max: 212,
      startOnTick: false,
      endOnTick: false,
      stops: [
        [0, 'rgba(211, 49, 56, 0.0)'],
        [0.1, 'rgba(211, 49, 56, 0.2)'],
        [0.2, 'rgba(211, 49, 56, 0.3)'],
        [0.3, 'rgba(211, 49, 56, 0.4)'],
        [0.5, 'rgba(211, 49, 56, 0.5)'],
        [0.6, 'rgba(211, 49, 56, 0.6)'],
        [0.7, 'rgba(211, 49, 56, 0.7)'],
        [0.8, 'rgba(211, 49, 56, 0.8)'],
        [0.9, 'rgba(211, 49, 56, 0.9)'],
        [1, ' #d33138'],
      ],
    },
    series: [
      {
        type: 'map',
        name: 'Number of sessions',
        states: {
          hover: {
            color: '#a166ab',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        allAreas: false,
        data: this.mapData,
      },
    ],
  };
  updateTable(_this: any, countryName: any, countryCode: any) {
    _this.isModalLoading = true;
    _this.tableTitle = `Session from ${countryName}`;
    let params = _this.generateParams();
    params += `&countryCode=${countryCode}`;
    _this.Service.tUserAndSessionByLocation(params).subscribe({
      next: (res: any) => {
        _this.modalTableData = res.data.filter(
          (item: any) => item._id !== null
        );
        _this.isModalLoading = false;
      },
      error: (err: any) => {
        console.log('error==>', err);
      },
      complete: () => {
        _this.isVisible = !_this.isVisible;
      },
    });
  }
  closeModal(visible: boolean) {
    this.isVisible = visible;
  }
  mapSessionsValue() {
    this.mapSessionsUpdate = true;
    this.mapChart.series = [
      {
        type: 'map',
        name: 'Number of sessions',
        states: {
          hover: {
            color: '#d33138',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        allAreas: false,
        data: this.mapData,
      },
    ];

    this.mapChart.plotOptions = {
      series: {
        point: {
          events: {
            click: (function (_this, updateTable) {
              return function () {
                updateTable(_this, this.name, this.properties['iso-a2']);
              };
            })(this, this.updateTable),
          },
        },
      },
    };
    setTimeout(() => {
      this.mapSessionsUpdate = false;
    }, 1000);
  }
}
