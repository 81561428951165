<nz-modal [(nzVisible)]="isVisible" nzCentered (nzOnOk)="handleOk()" (nzOnCancel)="handleCancel()"
  [nzContent]="nzModalContent" [nzFooter]="null">
  <ng-template #nzModalContent>
    <nz-table #basicTable [nzLoading]="isModalLoading" [nzData]="tableData" [nzShowPagination]="tableData?.length > 3"
      [nzPageSize]="3" nzTitle=" {{ tableTitle }}" (nzPageIndexChange)="onPageChange($event)"
      [nzPageIndex]="currentPage">
      <thead>
        <tr>
          <th>Sr.no</th>
          <th *ngFor="let col of tableColumns" [style.text-align]="col.align || 'left'">{{ col.header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rowData of basicTable.data let i = index">
          <td style="text-align: center;">{{ (currentPage - 1) * nzPageSize + i + 1 }}</td>
          <!-- Display serial number -->

          <td *ngFor="let col of tableColumns" [style.text-align]="col.align || 'left'">
            <ng-container *ngIf="col.type === 'date'; else regularColumn">
              {{ rowData[col.field] | date: 'yyyy-MM-dd HH:mm:ss' }}
            </ng-container>
            <!-- For other types of columns -->
            <ng-template #regularColumn>
              {{ rowData[col.field] }}
            </ng-template>
            <!-- {{ rowData[col.field] }} -->
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
</nz-modal>