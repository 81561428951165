<div class="login-main-container">
  <mat-card class="login">
    <h1 class="login-title">Login</h1>
    <div class="login-form-div">
      <input type="text" [(ngModel)]="userName" placeholder="username" class="login-input" />
      <nz-input-group [nzSuffix]="suffixTemplate" class="login-input">
        <input type="password" [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password"
          [(ngModel)]="password" name="password" required />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i style="cursor: pointer;" nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="passwordVisible = !passwordVisible"></i>
      </ng-template>
      <button class="login-btn" (click)="login()" type="submit">Login</button>
    </div>
  </mat-card>
</div>