import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-sharedcomponent',
  templateUrl: './sharedcomponent.component.html',
  styleUrls: ['./sharedcomponent.component.css'],
})
export class SharedcomponentComponent {
  drawerVisible: boolean = false; // Initially, the drawer is hidden
  role = localStorage.getItem('role');
  confirmModal?: NzModalRef;
  constructor(private router: Router, private modal: NzModalService) {}

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Are you sure you want to logout?',
      nzContent: 'Logging out will navigate you to login page.',
      nzOkText: 'Logout',
      nzOnOk: () => {
        this.logout();
      },
    });
  }
  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']); // Navigate to the login page
  }

  openDrawer(): void {
    this.drawerVisible = !this.drawerVisible;
  }

  closeModal(visible: boolean) {
    this.drawerVisible = visible;
  }
}
