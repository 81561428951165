<button nz-button nz-dropdown [nzDropdownMenu]="menu" class="dropdown-card">
  {{ selectedDomain ? selectedDomain : 'Select Domains' }} <span nz-icon nzType="down" class="dropdown-icon"></span>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <ul nz-menu nzSelectable>
      <li nz-menu-item (click)="selectDomain('All Domains')"> All Domains</li>
      <li nz-menu-item *ngFor="let domain of dropDownOption" (click)="selectDomain(domain)">{{domain}}</li>
    </ul>
  </ul>
</nz-dropdown-menu>