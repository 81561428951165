import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';

@Component({
  selector: 'app-mapchart',
  templateUrl: './mapchart.component.html',
  styleUrls: ['./mapchart.component.css'],
})
export class MapchartComponent {
  @Input() updateFlag: any = false;
  @Input() options: Highcharts.Options = {};
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'mapChart';
}
