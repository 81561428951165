<nz-drawer [nzClosable]="false" [nzVisible]="visible" [nzPlacement]="placement" (nzOnClose)="close()" nzWidth="185px"
  style="background: #3c2d79">
  <ng-container *nzDrawerContent>
    <div class="drawer-logo-div">
      <img src="../../assets/Images/altegon_logo-bg.png" alt="Altegon-logo" class="drawer-logo" />
      <span class="drawer-logo-title">ALTEGON</span>
    </div>
    <ul nz-menu nzTheme="light" class="side-menu-div" nzMode="inline">
      <li nz-menu-item routerLink="/dashboard/conference" routerLinkActive="active" (click)="close()">
        <!-- <span nz-icon nzType="dashboard" nzTheme="outline"></span> -->
        <span nz-icon nzType="youtube" nzTheme="outline"></span>
        <span>Conference</span>
      </li>
      <li nz-menu-item routerLink="/dashboard/webinar" routerLinkActive="active" (click)="close()">
        <!-- <span nz-icon nzType="dashboard" nzTheme="outline"></span> -->
        <span nz-icon nzType="video-camera-add" nzTheme="outline"></span>
        <span>Webinar</span>
      </li>
      <li nz-menu-item routerLink="/dashboard/user-management" routerLinkActive="active" (click)="close()">
        <span nz-icon nzType="user" nzTheme="outline"></span>
        <span>User</span>
      </li>
    </ul>
  </ng-container>
</nz-drawer>