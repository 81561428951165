import { Component, Input } from '@angular/core';

declare var require: any;
import * as Highcharts from 'highcharts';
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
require('highcharts/modules/networkgraph')(Highcharts);

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class ChartComponent {
  title = 'HighChartNetworkGraph';
  @Input() options: Highcharts.Options = {};
  @Input() updateFlag: any = false;
  Highcharts: typeof Highcharts = Highcharts;
}
