import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs'; // Import Observable from RxJS
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AllServicesService {
  private BaseUrl = environment.userManagement;
  constructor(private http: HttpClient) {}

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }

  login(userName: string, password: string): Observable<any> {
    const payload = { userName, password };
    return this.http.post(environment.auth.login, payload);
  }

  userList(): Observable<any> {
    let options = { headers: this.createHeaders() };
    return this.http.get(this.BaseUrl.userList, options);
  }
  userWebinar(userID: any): Observable<any> {
    let options = { headers: this.createHeaders() };
    return this.http.get(`${this.BaseUrl.userWebinar}/${userID}`, options);
  }
  userConference(userID: any): Observable<any> {
    let options = { headers: this.createHeaders() };
    return this.http.get(`${this.BaseUrl.userConference}/${userID}`, options);
  }

  // Move SweetAlert to a separate method for reusability and separation of concerns
  displaySuccessMessage(): void {
    Swal.fire({
      title: 'Success',
      text: 'Setting updated successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  }
}
