import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import 'highcharts/modules/solid-gauge';
import { Chart } from 'highcharts';

@Component({
  selector: 'app-meterchart',
  templateUrl: './meterchart.component.html',
  styleUrls: ['./meterchart.component.css'],
})
export class MeterchartComponent implements OnChanges {
  @Input() stunCount: number = 0;
  @Input() turnCount: number = 0;
  chart: Chart | undefined; // Store the chart instance

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stunCount'] || changes['turnCount']) {
      // Check if either stunCount or turnCount has changed
      this.renderChart();
    }
  }

  private renderChart() {
    if (this.chart) {
      // Destroy the existing chart if it exists
      this.chart.destroy();
    }

    // Define the chart options explicitly as Highcharts.Options
    const chartOptions: Highcharts.Options = {
      title: {
        text: 'Turn vs Stun Session',
        align: 'left',
        verticalAlign: 'top',
        y: 5,
      },
      exporting: {
        enabled: false, // Disable exporting functionality
      },
      tooltip: {
        pointFormat: `{series.name}: <b>{point.percentage:.1f}%</b> <br> Counts: <b>{point.y}</b>`,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 200,
        title: {
          text: 'Sessions',
        },
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        // verticalAlign: 'middle',
        margin: 0, // Set margin to 0 to remove any additional space
        // y: 10,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
              color: 'white',
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '50%'],
          size: '170%',
          showInLegend: true,
        },
      },
      colors: ['#d33138', '#d3d3d3'],
      // colors: ['#d33138cd', '#a166ab', '#3c2d79'],
      series: [
        {
          name: 'Sessions',
          data: [
            {
              name: 'Turn Sessions',
              y: this.turnCount,
            },
            {
              name: 'Stun Sessions',
              y: this.stunCount,
            },
          ],
          type: 'pie',
          innerSize: '60%',
        },
      ],
    };

    // Create a new chart with the updated data
    this.chart = Highcharts.chart('container-speed', chartOptions);
  }
}
