import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AuthGuard } from '../Guards/auth.guard';
import { SharedcomponentComponent } from './sharedcomponent.component';
import { WebinarComponent } from '../Components/webinar/webinar.component';
import { UserManagementComponent } from '../Components/user-management/user-management.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: SharedcomponentComponent,
    children: [
      {
        path: 'conference',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ['user', 'admin'] },
      },
      {
        path: 'webinar',
        component: WebinarComponent,
        canActivate: [AuthGuard],
        data: { roles: ['user', 'admin'] },
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        canActivate: [AuthGuard],
        data: { roles: ['user', 'admin'] },
      },
      { path: '', redirectTo: 'dashboard/conference', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedComponentRoutingModule {}
