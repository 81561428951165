<nz-layout class="layout">
  <nz-sider>
    <div class="logo-div">
      <img src="../../assets/Images/altegon_logo-bg.png" alt="Altegon-logo" class="logo" />
      <span class="logo-title">ALTEGON</span>
    </div>
    <ul nz-menu nzTheme="light" class="side-menu-div" nzMode="inline">
      <li nz-menu-item routerLink="/dashboard/conference" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <span nz-icon nzType="youtube" nzTheme="outline"></span>
        <!-- <span nz-icon nzType="dashboard" nzTheme="outline"></span> -->
        <span>Conference</span>
      </li>
      <li nz-menu-item routerLink="/dashboard/webinar" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <!-- <span nz-icon nzType="dashboard" nzTheme="outline"></span> -->
        <span nz-icon nzType="video-camera-add" nzTheme="outline"></span>
        <span>Webinar</span>
      </li>
      <li nz-menu-item routerLink="/dashboard/user-management" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"><span nz-icon nzType="user" nzTheme="outline"></span>
        <span>User</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout class="right-layout">
    <nz-header>
      <div class="header-logo-div">
        <span class="logout-btn" nz-icon nzType="menu" nzTheme="outline" (click)="openDrawer()"></span>
        <app-sider-drawer [visible]="drawerVisible" (visibleChange)="closeModal($event)"></app-sider-drawer>
        <!-- <img src="../../assets/Images/altegon_logo-bg.png" alt="Altegon-logo" class="header-logo" /> -->
        <span class="header-title">ALTEGON</span>

      </div>
      <span nz-icon nzType="logout" nzTheme="outline" class="logout-btn" (click)="showConfirm()"></span>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer><span class="footer-text">Altegon ©2020 Altegon All rights reserved</span></nz-footer>
  </nz-layout>
</nz-layout>