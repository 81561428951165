import { Component } from '@angular/core';
import { AllServicesService } from 'src/app/Services/allservices/all-services.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent {
  userList: any = [];
  isLoading = false; // Set to true initially
  isModalLoading = false;
  tableTitle = 'Details'; // title for the modal
  modalTableData: any;
  isVisible = false;
  usersTableColumn = [
    { header: 'User Name', field: 'name' },
    { header: 'Email', field: 'email', align: 'center' },
    { header: 'Region', field: 'region', align: 'center' },
    {
      header: 'No. of Conferences',
      field: 'conferenceCount',
      align: 'center',
      type: 'action',
      action: (rowData: any) => this.openModal(rowData, true),
    },
    {
      header: 'No. of Webinars',
      field: 'webinarCount',
      align: 'center',
      type: 'action',
      action: (rowData: any) => this.openModal(rowData, false),
    },
    {
      header: 'Registeration Date',
      field: 'createdAt',
      align: 'center',
      type: 'date',
    },
  ];
  modalTableColumn = [
    {
      header: 'No. of Participants',
      field: 'participantCount',
      align: 'center',
    },
    {
      header: 'Start Time',
      field: 'startTime',
      align: 'center',
      type: 'date',
    },
    { header: 'End Time', field: 'endTime', align: 'center', type: 'date' },
  ];
  constructor(private Service: AllServicesService) {
    this.getUserList();
  }
  ngOnInit() {
    this.isLoading = true;
  }
  getUserList() {
    this.Service.userList().subscribe({
      next: (res: any) => {
        this.userList = res.data.filter((item: any) => item._id !== null);
        this.isLoading = false;
      },
      error: (err: any) => {
        console.log('error==>', err);
      },
      complete: () => {},
    });
  }
  openModal(rowData: any, isConference: boolean) {
    this.isModalLoading = true;
    if (isConference) {
      this.Service.userConference(rowData._id).subscribe({
        next: (res: any) => {
          this.modalTableData = res.data;
          this.isModalLoading = false;
        },
        error: (err: any) => {
          console.log('error==>', err);
        },
        complete: () => {},
      });
      this.tableTitle = `Detail for ${rowData.name}'s Conference`;
    } else {
      this.Service.userWebinar(rowData._id).subscribe({
        next: (res: any) => {
          this.modalTableData = res.data;
          this.isModalLoading = false;
        },
        error: (err: any) => {
          console.log('error==>', err);
        },
        complete: () => {},
      });
      this.tableTitle = `Detail for ${rowData.name}'s Webinar`;
    }
    this.isVisible = true; // show the modal
  }
  closeModal(visible: boolean) {
    this.isVisible = visible; // hide the modal
    this.modalTableData = [];
  }
}
