import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ChartModule } from 'angular-highcharts';
import { ToastrModule } from 'ngx-toastr';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { AppComponent } from './app.component';
import { TableComponent } from './Components/table/table.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RangepickerComponent } from './Components/rangepicker/rangepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgFor, registerLocaleData } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { ChartComponent } from './Components/chart/chart.component';
import { SharedcomponentComponent } from './sharedcomponent/sharedcomponent.component';
import en from '@angular/common/locales/en';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { MapchartComponent } from './Components/mapchart/mapchart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LoginComponent } from './Components/login/login.component';
import { SharedComponentRoutingModule } from './sharedcomponent/sharedcomponent-routing.module';
import { DropdownComponent } from './Components/dropdown/dropdown.component';
import { MeterchartComponent } from './Components/meterchart/meterchart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SiderDrawerComponent } from './Components/sider-drawer/sider-drawer.component';
import { ModalComponent } from './Components/modal/modal.component';
import { WebinarComponent } from './Components/webinar/webinar.component';
import { UserManagementComponent } from './Components/user-management/user-management.component';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    RangepickerComponent,
    ChartComponent,
    SharedcomponentComponent,
    MapchartComponent,
    TableComponent,
    LoginComponent,
    DropdownComponent,
    MeterchartComponent,
    SiderDrawerComponent,
    ModalComponent,
    WebinarComponent,
    UserManagementComponent,
  ],
  imports: [
    ChartModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    NgFor,
    MatInputModule,
    FormsModule,
    MatCardModule,
    DemoNgZorroAntdModule,
    HttpClientModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    SharedComponentRoutingModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
    }), // ToastrModule added
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
