import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent {
  @Input() data: any;
  @Input() isLoading: boolean = true;
  // @Input() columns: { header: string; field: string; align?: string }[] = [];
  @Input() columns: {
    header: string;
    field?: string;
    align?: string;
    type?: string;
    action?: (rowData: any) => void; // Add the action method signature
    showAction?: (rowData: any) => void; // Add the action method signature
  }[] = [];

  // Pagination properties
  @Input() showPagination: boolean = true;
  @Input() pageSize: number = 8;

  currentPage = 1; // Initialize to the first page
  nzPageSize = 5; // This should match your [nzPageSize] in the template

  // Handler for page changes
  onPageChange(event: number): void {
    this.currentPage = event;
  }

  // You may also need a handler for page size changes if that's an option in your UI
  onPageSizeChange(newSize: number): void {
    this.nzPageSize = newSize;
  }
}
