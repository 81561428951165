const BASE_ENDPOINT = 'https://video.altegon.com';
const turn_endpoint = 'https://global.xirsys.net/_stats/?l=turn:session';
const stun_endpoint = 'https://global.xirsys.net/_stats/?l=stun:session';
const turn_bandwidth1 = 'https://global.xirsys.net/_stats/?l=turn:recv';
const turn_bandwidth2 = 'https://global.xirsys.net/_stats/?l=turn:sent';
const total_bandwidth =
  'https://global.xirsys.net/dashboard/api/account?dataType=json&type=GET&email=devops%40zyyah.com';

export const environment = {
  production: false,
  BASE_ENDPOINT: `${BASE_ENDPOINT}`,
  auth: {
    get login() {
      return `${BASE_ENDPOINT}/auth/login`;
    },
  },
  reporting: {
    get listDomain() {
      return `${BASE_ENDPOINT}/reporting/list-domains`;
    },
    get abandonedSession() {
      return `${BASE_ENDPOINT}/reporting/abandoned-sessions`;
    },
    get activeHostedSession() {
      return `${BASE_ENDPOINT}/reporting/active-hosted-sessions`;
    },
    get averageTimeSession() {
      return `${BASE_ENDPOINT}/reporting/average-time-sessions`;
    },
    get averageUserSession() {
      return `${BASE_ENDPOINT}/reporting/average-users-sessions`;
    },
    get totalUser() {
      return `${BASE_ENDPOINT}/reporting/total-users`;
    },
    get totalVsHostedSession() {
      return `${BASE_ENDPOINT}/reporting/total-vs-hosted-vs-abandoned-sessions`;
    },
    get sessionByWeek() {
      return `${BASE_ENDPOINT}/reporting/total-sessions-aggregation`;
    },
    get sessionByLocation() {
      return `${BASE_ENDPOINT}/reporting/total-sessions-by-location`;
    },
    get userAndSessionByLocation() {
      return `${BASE_ENDPOINT}/reporting/total-sessions-and-participants-count-by-location`;
    },
    get stun() {
      return `${stun_endpoint}`;
    },
    get turn() {
      return `${turn_endpoint}`;
    },
    get bandwidth1() {
      return `${turn_bandwidth1}`;
    },
    get bandwidth2() {
      return `${turn_bandwidth2}`;
    },
    get totalBandwidth() {
      return `${total_bandwidth}`;
    },
  },
  webinar: {
    get listDomain() {
      return `${BASE_ENDPOINT}/webinar/reporting/list-domains`;
    },
    get abandonedSession() {
      return `${BASE_ENDPOINT}/webinar/reporting/abandoned-sessions`;
    },
    get activeHostedSession() {
      return `${BASE_ENDPOINT}/webinar/reporting/active-hosted-sessions`;
    },
    get averageTimeSession() {
      return `${BASE_ENDPOINT}/webinar/reporting/average-time-sessions`;
    },
    get averageUserSession() {
      return `${BASE_ENDPOINT}/webinar/reporting/average-users-sessions`;
    },
    get totalUser() {
      return `${BASE_ENDPOINT}/webinar/reporting/total-users`;
    },
    get totalVsHostedSession() {
      return `${BASE_ENDPOINT}/webinar/reporting/total-vs-hosted-vs-abandoned-sessions`;
    },
    get sessionByWeek() {
      return `${BASE_ENDPOINT}/webinar/reporting/total-sessions-aggregation`;
    },
    get sessionByLocation() {
      return `${BASE_ENDPOINT}/webinar/reporting/total-sessions-by-location`;
    },
    get userAndSessionByLocation() {
      return `${BASE_ENDPOINT}/webinar/reporting/total-sessions-and-participants-count-by-location`;
    },
  },
  userManagement: {
    get userList() {
      return `${BASE_ENDPOINT}/user/reporting/user-session-aggregation`;
    },
    get userConference() {
      return `${BASE_ENDPOINT}/user/reporting/user-conference-detail`;
    },
    get userWebinar() {
      return `${BASE_ENDPOINT}/user/reporting/user-webinar-detail`;
    },
  },
};
