<div class="dashboard-container">
    <app-modal [isVisible]="isVisible" [isModalLoading]="isModalLoading" [tableData]="modalTableData"
        [tableColumns]="modalTableColumns" (onClose)="closeModal($event)" [tableTitle]="tableTitle"></app-modal>
    <div class="dashboard-rangePicker-div">
        <app-dropdown [dropDownOption]="domainList" (selectedDomainChange)="onDomainSelected($event)"></app-dropdown>
        <app-rangepicker (dateRangeChange)="onDateRangeChange($event)"></app-rangepicker>
    </div>
    <div class="dashboard-card-div">
        <mat-card class="dashboard-card custom-shadow " *ngFor="let card of cardData">
            <mat-card-content>
                <div class="card-inner-div">
                    <div class="card-upper">
                        <p class="card-title">{{ card.title }}</p>
                        <span *ngIf="card.title === 'Total Users'" class="card-icon" nz-icon nzType="team"
                            nzTheme="outline"></span>
                        <span *ngIf="card.title === 'Avg Time /Session'" class="card-icon" nz-icon nzType="field-time"
                            nzTheme="outline"></span>
                        <span *ngIf="card.title === 'Avg Users /Session'" class="card-icon" nz-icon nzType="team"
                            nzTheme="outline"></span>
                    </div>
                    <p class="card-num">
                        {{ card.animatedNumber }}
                        <span *ngIf="card.title === 'Avg Time /Session'" class="card-span">{{timeUnit}}</span>
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="dashboard-chart-container">
        <mat-card class="dashboard-bar-chart custom-shadow ">
            <app-chart [options]="totalSessionsByDomain" [updateFlag]="totalSessionsByDomainUpdate"></app-chart>
        </mat-card>
        <mat-card class="dashboard-column-chart custom-shadow ">
            <app-chart [options]="totalUsersByDomain" [updateFlag]="totalUsersByDomainUpdate"></app-chart>
        </mat-card>
    </div>
    <div class="dashboard-chart-container">
        <mat-card class="dashboard-week-bar-chart custom-shadow ">
            <app-chart [options]="totalSessionsByWeek" [updateFlag]="totalSessionsByWeekUpdate"></app-chart>
        </mat-card>
    </div>

    <div class="map-chart-div">
        <mat-card class="map-chart-container custom-shadow ">
            <app-mapchart [options]="mapChart" [updateFlag]="mapSessionsUpdate"></app-mapchart>
        </mat-card>
    </div>
    <div class="table-div">
        <mat-card class="dashboard-table-container custom-shadow ">
            <app-table [data]="tableData" [isLoading]="isLoading" [columns]="tableColumn"
                [showPagination]="tableData?.length > 5" [pageSize]="5"></app-table>
        </mat-card>
    </div>
</div>